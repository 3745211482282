<template>
	<manageLayout>
		<div class="p-inside-manage">
			<h1>Events</h1>
			<button type="button" class="create_btn secondary_btn small" v-ripple @click="redirectToAddEvent">Add an Event</button>
			<div class="wrap_manage_table">
				<div class="top_bar">
					<div class="tabs_section">
						<ul>
							<li :class="{'active': topFilter === 'current'}" @click="changeTopFilter('current')">Current</li>
							<li :class="{'active': topFilter === 'past'}" @click="changeTopFilter('past')">Past</li>
							<li :class="{'active': topFilter === 'drafts'}" @click="changeTopFilter('drafts')">Drafts</li>
						</ul>
					</div>
					<div class="search_section">
						<span class="icon"></span>
						<input type="text" placeholder="Search events" v-model="searchEvents">
					</div>
				</div>

				<eventsTable :dataTable="events"></eventsTable>
			</div>
		</div>
		<comingSoonDialog v-if="modalComingSoonDialog" v-model="modalComingSoonDialog"></comingSoonDialog>
	</manageLayout>
</template>


<script>
import manageLayout from "@/layouts/manageLayout";
import {mapGetters, mapActions} from "vuex";
import eventsTable from "@/components/manage/eventsTable.vue";
import comingSoonDialog from '@/components/dialogs/comingSoonDialog.vue';

export default {
	name: "manageEvents",
	components: {
		manageLayout,
		eventsTable,
		comingSoonDialog
	},
	data() {
		return {
			searchEvents: '',
			modalComingSoonDialog: false
		}
	},
	watch: {
		searchEvents() {
			this.getEventList();
		}
	},
	computed: {
		...mapGetters({
			userID: 'userID',
			topFilter: 'manageUploadsEvents/TOP_FILTER',
			events: 'events/EVENTS',
			eventsLoading: 'events/EVENTS_LOADING',
			countEvents: 'events/COUNT_EVENTS',
			currentPage: 'events/CURRENT_PAGE',
			isAdmin: 'isAdmin'
		})
	},
	mounted() {
		this.getEventList();
	},
	methods: {
		...mapActions({
			getEvents: 'events/GET_EVENTS',
		}),
		getEventList() {
			let params = {
				page: this.currentPage,
				title: this.searchEvents,
				owner: this.userID
			}
			this.getEvents(params)
				.then(() => {
				})
				.catch(err => console.log(`GET_events, ${err}`))
		},
		changeTopFilter(filter) {
			this.$store.commit('manageUploadsEvents/SET_TOP_FILTER', filter);
		},
		redirectToAddEvent() {
			if (this.isAdmin === true) {
				this.$router.push({name: 'createEvent'});
			} else {
				this.modalComingSoonDialog = true;
			}
		}
	}
}
</script>

<style scoped>

</style>